<template>
    <div class="zt-page-content">
        <div class="header">
            <div class="search-bar flex flex-align-center">
                <div style="width:100px;font-size:18px;color:rgba(41,41,41,1);"><b>设计版权</b></div>
                <el-input v-model="query.k" @change="handleChange" placeholder="作品名" style="max-width: 500px;margin-left: 50px;">
                    <el-button @click="search()" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </div>
        <div class="zt-block flex flex-align-center" style="padding:20px">
            <div class="select-wrap flex flex-align-center">
                <label>风格</label>
                <el-select v-model="query.style_no" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                    <el-option v-for="item in config.styles" :key="item.id"
                    :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <!-- <div class="select-wrap flex flex-align-center">
                <label>类目</label>
                <el-cascader :options="config.cats" @change="handleChange" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                    v-model="query.cat_id" placeholder="全部" size="small">
                </el-cascader>
            </div> -->
            <div class="select-wrap flex flex-align-center">
                <label>状态</label>
                <el-select v-model="query.stat" @change="handleChange" :popper-append-to-body="false" placeholder="全部" size="small">
                    <el-option v-for="item in config.sale_channel" :key="item.id"
                    :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="select-wrap flex-1 flex">
                <div class="flex-1"></div>
                <div class="head flex">
                    <div class="flex flex-align-center">
                        <span @click="sort('time')" :class="{'active': opt.sort_by!='price' }">按时间排序</span>
                        <img @click="sort('up')" 
                            v-if="opt.sort_by!='price' && opt.sort_order!=1" 
                            src="@/assets/images/factory/sort_down.png">
                        <img @click="sort('down')" 
                            v-if="opt.sort_by!='price' && opt.sort_order==1" 
                            src="@/assets/images/factory/sort_up.png">
                    </div>
                    <div class="flex flex-align-center">
                        <span @click="sort('price')" :class="{'active': opt.sort_by=='price' }">按价格排序</span>
                        <img @click="sort('up')" 
                            v-if="opt.sort_by=='price' && opt.sort_order!=1" 
                            src="@/assets/images/factory/sort_down.png">
                        <img @click="sort('down')" 
                            v-if="opt.sort_by=='price' && opt.sort_order==1" 
                            src="@/assets/images/factory/sort_up.png">
                    </div>
                </div>
            </div>
        </div>
        <div style="height:calc(100% - 220px);background:#fff;margin:0 20px" class="flex flex-v">
            <div class="list flex-1" style="height:100%;overflow-y:auto;">
                <div v-if="list && list.length>0">
                    <div v-for="(item,idx) in list" :key="idx" class="list-item">
                        <div class="list-item-top flex">
                            <div @click="gotoDetail(item)" style="width:180px;height:180px;margin-right:12px;">
                                <div v-if="item.photo_render && item.photo_render.length>0" class="bg_img"
                                    :style="{backgroundImage: 'url(' + IMG_URL_PRE+item.photo_render[0]+'!width_250px' + ')'}"></div>
                                    <div v-if="item.photos && item.photos.length>0" class="bg_img"
                                    :style="{backgroundImage: 'url(' + IMG_URL_PRE+item.photos[0]+'!width_250px' + ')'}"></div>
                            </div>
                            <div class="lit-right flex-1">
                                <div style="70%">
                                    <h3 v-if="item.name" @click="goto('/factory/qygl/detail/'+item.trans_no)" class="item-name ellipsis2">{{item.name}}</h3>
                                    <div class="item-series flex">
                                        <span v-if="item.style">{{item.style.name}}</span>
                                        <span v-if="item.style && item.cat" style="margin:0 4px"> | </span>
                                        <span v-if="item.cat">{{item.cat.name}}</span>
                                    </div>
                                    <div class="item-time">发起时间：{{item.created_at | moment('YYYY/MM/DD HH:mm:ss')}}</div>
                                    <div class="item-price">
                                        <span v-if="item.product_type==1">单品价格：</span>
                                        <span v-if="item.product_type==2">套系价格：</span>
                                        <span class="price">￥<span>{{item.price | priceFilt}}</span></span>
                                    </div>
                                </div>
                                <div class="item-avatar flex flex-align-center">
                                    <div class="avatar">
                                        <div v-if="item.design_comp && item.design_comp.logo" class="bg_img"
                                            :style="{backgroundImage: 'url(' + IMG_URL_PRE+item.design_comp.logo + ')'}"></div>
                                        <div v-else class="bg_img"
                                            :style="{backgroundImage: 'url(' + staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px' + ')'}"></div>
                                    </div>
                                    <div v-if="item.design_comp">{{item.design_comp.name}}</div>
                                </div>
                                <div class="item-type">
                                    <img v-if="item.product_type==1" 
                                        src="@/assets/images/desgin/product_type1.png" alt="">
                                    <img v-if="item.product_type==2" 
                                        src="@/assets/images/desgin/product_type2.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="list-item-bottom">
                            <div v-if="item.trans_stat<0">
                                <div style="text-align:center;font-size:32px;padding:10px 0;">暂无工厂签约</div>
                            </div>
                            <div v-if="item.trans_stat>=0">
                                <SignPlan v-if="item && item.id" :item="item" :planType="'bq_order'" @refresh="refreshItems"></SignPlan>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div v-if="list && list.length==0" class="flex flex-center" style="text-align:center;height:100%;">
                    <div>
                        <img style="width:110px" :src="staticUrl+'/design/placeholder/meiyougengduo.png'" alt="">
                        <div style="font-size:16px;color:#808080;padding:15px 0;">暂无相关数据</div>
                    </div>
                </div>
            </div>
            <div v-if="pageShow" class="pagination-wrap flex">
                <el-pagination background @current-change="currentChange"
                    layout="prev, pager, next"
                    :current-page.sync="opt.page"
                    :page-size="opt.pagesize"
                    :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import { IMG_URL_PRE, STATIC_URL_PRE } from '@/config';
    import { getCats, getStyles } from '@/service/item';
    import { fetchTree } from '@/util';
    import { listProductOrder } from '@/service/design';
    import SignPlan from '@/components/tradeOrder/Seller.vue';
    export default {
        components: {
            SignPlan
        },
        data() {
            return {
                IMG_URL_PRE,
                staticUrl:STATIC_URL_PRE,
                config:{
                    styles: null, // 风格 
                    cats: null, // 类目
                    sale_channel:[
                        { id: 0, name: "全部" },
                        { id: 2, name: "等待我方签约" },
                        // { id: 4, name: "等待对方签约" },
                        { id: 6, name: "交易完成" },
                    ]
                },
                query:{
                    k: "",
                    style_no: "", // 风格编号列表
                    // cat_id: null,
                    stat: 0, // 状态列表，0已创建，1买方发起签合同，2买方已签合同，3卖方发起签合同，4卖方已签合同，5买方已付款，6卖方确认收款，7卖方已发货，8买方已收货，9买方已评价，10卖方已评价，11交易完成，21> 卖方已修改商品信息，22库存不足
                    // sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    // sort_order: "", // 默认降序，传1表示升序
                },
                opt:{
                    k: "",
                    style_no: "", // 风格编号列表
                    // cat_id: null,
                    stat: 0, // 状态列表，0已创建，1买方发起签合同，2买方已签合同，3卖方发起签合同，4卖方已签合同，5买方已付款，6卖方确认收款，7卖方已发货，8买方已收货，9买方已评价，10卖方已评价，11交易完成，21> 卖方已修改商品信息，22库存不足
                    sort_by: "", // 默认按发布时间排序，可以传price按价格排序
                    sort_order: "", // 默认降序，传1表示升序
                    page: 1,
                    pagesize: 10,
                },
                total:0,
                pageShow:false, // 强制刷新分页组件
                list: [],
            }
        },
        watch: {
            // query:{
            //     handler() {
            //         this.handleChange();
            //     },
            //     deep: true
            // },
            $route(to,from){
                console.log("to",to);
                this.opt.k = this.query.k = this.$route.query.k;
                if (this.$route.query.style_no) {
                    this.query.style_no = this.$route.query.style_no;
                } else this.query.style_no = "";
                this.opt.style_no = [this.query.style_no];
                if (this.$route.query.stat) {
                    this.query.stat = parseInt(this.$route.query.stat);
                } else this.query.stat = 0;
                if (this.query.stat==6) {
                    this.opt.stat = [6,11];
                } else if (this.query.stat==0) {
                    this.opt.stat = [2,3,4,5,6,7,8,9,10,11,21];
                } else {
                    this.opt.stat = [this.query.stat];
                }
                if (this.$route.query.page) {
                    this.opt.page=parseInt(this.$route.query.page);
                }
                this.refreshItems();
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
            }
        },
        async created() {
            this.catList = await getCats();
            this.config.cats = fetchTree(this.catList, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            console.log(this.config.cats);
            this.config.styles = [{ id:"", name:"全部" }].concat(await getStyles());
        },
        mounted() {
            if (this.query.sort_by) {
                this.opt.sort_by = this.query.sort_by;
            }
            if (this.query.sort_order) {
                this.opt.sort_order = this.query.sort_order;
            }
            this.opt.k = this.query.k = this.$route.query.k;
            if (this.$route.query.style_no) {
                this.query.style_no = this.$route.query.style_no;
            } else this.query.style_no = "";
            this.opt.style_no = [this.query.style_no];
            if (this.$route.query.stat) {
                this.query.stat = parseInt(this.$route.query.stat);
            } else this.query.stat = 0;
            if (this.query.stat==6) {
                this.opt.stat = [6,11];
            } else if (this.query.stat==0) {
                this.opt.stat = [2,3,4,5,6,7,8,9,10,11,21];
            } else {
                this.opt.stat = [this.query.stat];
            }
            if (this.$route.query.page) {
                this.opt.page=parseInt(this.$route.query.page);
            }
            this.refreshItems();
            this.$nextTick(()=>{
                this.pageShow = true;
            })
        },
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            handleChange() {
                console.log("this.opt",this.opt);
                // this.opt.style_no = [this.query.style_no];
                // if (this.query.stat==6) {
                //     this.opt.stat = [6,11];
                // } else if (this.query.stat==0) {
                //     this.opt.stat = [2,3,4,5,6,7,8,9,10,11,21];
                // } else {
                //     this.opt.stat = [this.query.stat];
                // }
                // this.opt.page = 1;
                // this.$router.push({
                //     query:{ page:this.opt.page, style: this.query.style_no, s:this.query.stat}
                // });
                this.$router.push({
                    query:{
                        k: this.query.k,
                        style_no: this.query.style_no,
                        stat: this.query.stat,
                        page: 1,
                    } 
                });
                this.refreshItems();
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
            },
            sort(type) { // 排序
                if (type=="time") {
                    this.opt.sort_by = "";
                    this.opt.sort_order = "";
                } else if (type=="price") {
                    this.opt.sort_by = "price";
                    this.opt.sort_order = "";
                } else if (type=="up") {
                    this.opt.sort_order = 1;
                } else if (type=="down") {
                    this.opt.sort_order = "";
                }
                this.refreshItems();
            },
            gotoDetail(item) {
                if (item.product_type==1) {
                    this.$router.push("/desgin/stored/single/detail/"+item.id);
                } else if (item.product_type==2) {
                    this.$router.push("/desgin/stored/series/detail/"+item.id);
                }
                
            },
            currentChange(e) { // 监听分页
                this.opt.page = e;
                this.$router.push({
                    query:{ 
                        type: this.$route.query.type, 
                        k: this.$route.query.k,
                        style_no: this.$route.query.style_no,
                        stat: this.$route.query.stat,
                        page:e,
                    }
                });
                this.refreshItems();
                this.$nextTick(()=>{
                    this.pageShow = true;
                })
            },
            refreshItems() {
                console.log('refreshItems');
                listProductOrder(this.opt).then(rst => {
                    this.total=rst.total;
                    this.list = rst.list;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.header {
    width: 100%;
    padding: 0 60px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px #D1D9E2;
    .search-bar {
        padding: 24px 0 24px;
    }
    .tab_menu {
        .menu_item {
            height: 38px;
            line-height: 38px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #24B1EB;
                border-bottom: 2px solid #24B1EB;
            }
        }
    }
}
.select-wrap {
    margin-right: 30px;
    label {
        font-size: 14px;
        color: #292929;
        margin-right: 15px;
    }
    .head {
        font-size: 14px;
        color: #4D4D4D;
        line-height: 20px;
        >div {
            cursor: pointer;
            margin-right: 35px;
            span {
                &.active {
                    color: #24B1EB;
                }
            }
            img {
                width: 14px;
                height: 21px;
                margin-left: 6px;
            }
        }
    }
}
.list {
    // padding: 0 20px;
    padding: 20px;
}
.list-item {
    font-size: 14px;
    color: #808080;
    border: 1px solid #E6E6E6;
    position: relative;
    margin-bottom: 20px;
    .list-item-top {
        padding: 20px;
        border-bottom:1px solid rgba(204,204,204,1);
    }
    .list-item-bottom {
        padding: 20px;
    }
    .lit-right {
        min-width: 320px;
        position: relative;
    }
    .item-name {
        font-size: 16px;
        color: #292929;
        line-height: 24px;
        margin-bottom: 8px;
        font-weight: bold;
    }
    .item-series {
        font-size: 12px;
        color: #B3B3B3;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .item-time {
        font-size: 14px;
        color: #808080;
        margin-bottom: 8px;
    }
    .item-price {
        font-size: 14px;
        color: #808080;
        line-height: 32px;
        .price {
            font-size: 16px;
            color: #F66F6A;
            span {
                font-size: 24px;
            }
        }
    }
    .item-avatar {
        padding: 12px 0;
        border-top: 1px solid #E6E6E6;
        margin-top: 12px;
        font-size: 14px;
        color: #292929;
        .avatar {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 6px;
        }
    }
    .item-type {
        position: absolute;
        top: 0; right: 0;
        img {
            width: 65px;
        }
    }
    .item-plan {
        margin: 9px 0 0 25px;
    }
}
.pagination-wrap {
    padding:8px 55px;
    text-align:center;
}
</style>